export class SignUpLearnerRequestModel {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    genderId: number;
    nationalityId: number;
    birthDate: string;
    organizationName: string;
    optInMarketing: boolean;
    isTemporaryUser: boolean;
}
