import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LessonPlanApiVariables {
    private readonly AugmentalBaseApiServer = environment.baseApiServer;
    private readonly AugmentalBaseApiServerBff = environment.baseApiServerBff;

    public readonly ApiGenerateLesson = this.AugmentalBaseApiServerBff + '/api/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/Automated';
    public readonly ApiGenerateLessonV2 = this.AugmentalBaseApiServerBff + '/api/V2/Institutions/{0}/Instructors/{1}/Courses/{2}/SubCourses/Automated';
}
