import { NotionResponseModel } from '../notion/response/notion-response-model';
import { ContentModelResponseModel } from './response/content-response-model';

/**
 * Pass data when navigating from content container to content details
 */
export class ContentNavigationSM {
    itemInfo: ContentModelResponseModel;
    notion: NotionResponseModel;

    isNewContent: boolean;

    isBeginner: boolean;
    isIntermediate: boolean;
    isAdvanced: boolean;

    isVideo: boolean;
    isImage: boolean;
    isAudio: boolean;
    isText: boolean;
    isMixedContent: boolean;
    isContentLibrary: boolean;
}
