import { CourseMasteryLearnersProgressPercentageDetailResponseModel } from "./course-mastery-progress-details-response.model";
import { CourseMasteryLearnersProgressPercentageTableColumnsResponseModel } from "./course-mastery-progress-table-column-response.model";

export class CourseMasteryLearnersProgressPercentageMainResponseModel {
    photo: string;
    title: string;
    firstName: string;
    lastName: string;
    description: string;
    tableColumns: CourseMasteryLearnersProgressPercentageTableColumnsResponseModel[];
    userProgress: CourseMasteryLearnersProgressPercentageDetailResponseModel[];
}