import { Injectable } from '@angular/core';
import { TranslationService } from '../../../../../../src/app/config/services/translation.service';
import { SubscriptionResponseModel } from '../../../../../../src/app/core/models/shared/subscription/response/subscription.response.model';
import { KnowledgeBaseKey } from '../../../../../../src/app/shared/helper/knowledge-base/knowledge-base.enum';
import { KnowledgeBaseModel } from '../../../../../../src/app/shared/models/knowledge-base/knowledge-base.model';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class KnowledgeBaseViewModel {
    constructor(private translationService: TranslationService) { }

    /**
     * @name courseSetupPackageList
     * @returns
     * Retruns a list of course setup
     */
    public courseSetupPackageList(): KnowledgeBaseModel[] {
        var knowledgeBaseList: KnowledgeBaseModel[] = [];

        var knowledgeBase: KnowledgeBaseModel = JSON.parse(JSON.stringify(environment.knowledgeBase.course.find((item) => item.key == KnowledgeBaseKey.CourseLessonPlan)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.course.find((item) => item.key == KnowledgeBaseKey.CourseContentQuestions)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.course.find((item) => item.key == KnowledgeBaseKey.CoursePublish)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.course.find((item) => item.key == KnowledgeBaseKey.CourseMonetize)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        return knowledgeBaseList;
    }

    /**
     * @name accountManagementPackageList
     * @returns
     * Retruns a list of account management
     */
    public accountManagementPackageList(): KnowledgeBaseModel[] {
        var knowledgeBaseList: KnowledgeBaseModel[] = [];

        var knowledgeBase: KnowledgeBaseModel = JSON.parse(JSON.stringify(environment.knowledgeBase.account.find((item) => item.key == KnowledgeBaseKey.AccountManageType)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.account.find((item) => item.key == KnowledgeBaseKey.AccountManageBranding)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.account.find((item) => item.key == KnowledgeBaseKey.AccountManageSubAccounts)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.account.find((item) => item.key == KnowledgeBaseKey.AccountManageCustomDomain)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        if (!SubscriptionResponseModel.Instance.isCustomDomainEnabled) {
            knowledgeBase.isLocked = true;
        }
        knowledgeBaseList.push(knowledgeBase);

        knowledgeBase = JSON.parse(JSON.stringify(environment.knowledgeBase.integrations.find((item) => item.key == KnowledgeBaseKey.IntegrationsPaymentGateawy)));
        knowledgeBase.title = this.translationService.GetTranslatedValue(knowledgeBase.title); //replaces the key with the translation
        knowledgeBase.description = this.translationService.GetTranslatedValue(knowledgeBase.description); //replaces the key with the translation
        knowledgeBaseList.push(knowledgeBase);

        return knowledgeBaseList;
    }
}
