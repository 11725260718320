import { Injectable } from '@angular/core';
import { LessonPlanApiVariables } from '../../http/http-call/apis/admin/course/lesson/lesson-plan.api';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root',
})
export class LessonGenerationApplicationService {
    constructor(private lessonPlanApiVariables: LessonPlanApiVariables, private globalApiVariables: GlobalApiVariables, private http: HttpCall) { }

    /**
     * @name generateLesson
     * @param institutionId
     * @param userId
     * @param courseId
     * @param body
     * @returns
     */
    public generateLesson(institutionId: number, userId: number, courseId: number, body: string) {
        var data = this.http.PostAsync(true, body, this.lessonPlanApiVariables.ApiGenerateLesson, institutionId, userId, courseId);
        return data;
    }

    /**
     * @name generateLesson
     * @param institutionId
     * @param userId
     * @param courseId
     * @param body
     * @returns
     */
    public generateLessonV2(institutionId: number, userId: number, courseId: number, body: string) {
        var data = this.http.PostAsync(true, body, this.lessonPlanApiVariables.ApiGenerateLessonV2, institutionId, userId, courseId);
        return data;
    }
}
