import { CourseLearningOutcomePerformanceSectionResponseModel } from '../../report/response/learning-outcome/course-learning-outcome-performance-section-response.model';
import { MainStatisticsInformationResponseModel } from '../../report/response/main-statistics-information-response.model';
import { ClassInformationResponseModel } from './../../report/response/report-class-information-response.model';
import { AssessmentVisibilityResponseModel } from './assessment-visibility-response.model';
import { HighCorrectIncorrectTableMainResponseModel } from './high-correct-incorrect-table-response.model';
import { HighCorrectIncorrectTableValuesResponseModel } from './high-correct-incorrect-values-response.model';
export class AssessmentFullOverviewResponseModel {
    assessmentTitle: string;
    canLaunchAssessment: boolean;
    overview: ClassInformationResponseModel[];
    summary: ClassInformationResponseModel[];
    information: ClassInformationResponseModel[];
    configurations: ClassInformationResponseModel[];
    visibility: AssessmentVisibilityResponseModel[];
    settings: ClassInformationResponseModel[];
    learningOutcomes: string[];
    statistics: MainStatisticsInformationResponseModel[];
    highestLowestPerformingCourseLearningOutcomes: CourseLearningOutcomePerformanceSectionResponseModel;
    highCorrectIncorrectAnswersTable: HighCorrectIncorrectTableMainResponseModel[];
    isTopHighCorrectAnswers: boolean;
    isTopHighIncorrectAnswers: boolean;
}