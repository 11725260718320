import { LessonGenerationLevelDetailsRequestModel } from './lesson-generation-level.request.model';

export class LessonGenerationSubcourseRequestModel {
    isAutoAssignedNumberOfSubCourses: boolean;
    totalNumberOfSubCourses: number;
    isAutoAssignedNumberOfNotions: boolean;
    isAutoAssignedLessonLevel: boolean;
    assignedLessonLevelDetails: LessonGenerationLevelDetailsRequestModel;
    learningOutcomes: string[];
}
