import { LearningOutcomeApplicationService } from './../../../../../core/services/learning-outcome/learning-outcome-application.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { NotionElementResponseModel } from '../../../../../../../src/app/core/models/builder/content/notion-element-info-model';
import { NotionWithElementsResponseModel } from '../../../../../../../src/app/core/models/builder/content/notion-element-response-model';
import { ContentElementContainerApplicationService } from '../../../../../../../src/app/core/services/content-builder/content/content-element-container-application-service.service';
import { NotionApplicationService } from '../../../../../../../src/app/core/services/content-builder/notion/notion-application-service.service';
import { DialogData } from '../../../../../../../src/app/shared/components/dialog-builder/dialog-single-input-model';
import { DialogLoading } from '../../../../../../../src/app/shared/components/dialog-builder/loading/dialog-loading.component';
import { SnackBar } from '../../../../../../../src/app/shared/components/snackbar/snackbar';
import { TranslationService } from '../../../../../config/services/translation.service';

@Injectable({
    providedIn: 'root'
})
export class MainContainerBuilderViewModel {
    private subscriptions: Subscription[] = [];

    constructor(
        private service: ContentElementContainerApplicationService,
        private notionService: NotionApplicationService,
        private learningOutcomeService: LearningOutcomeApplicationService,
        public translationService: TranslationService,
        public _snackBar: SnackBar,
        public dialog: MatDialog
    ) { }

    /**
     * @name ChangeNotionElementContainersOrder
     * @param oldItemIndex
     * @param newItemIndex
     * @param components
     * @param container
     * Change the order of the main content/exercise containers once updated in the side nav
     */
    ChangeNotionElementContainersOrder(
        oldItemIndex: number,
        newItemIndex: number,
        components: any[],
        container: ViewContainerRef
    ) {
        const component = components[oldItemIndex];
        // container.indexOf(component);

        // console.log('Inside the Listener');
        // console.log('old index : ' + oldItemIndex);
        // console.log('new index : ' + newItemIndex);
        // console.log('container length : ' + container.length);
        // console.log(component);
        // console.log(container.get(container.indexOf(component)));

        container.move(container.get(container.indexOf(component)), newItemIndex);

        moveItemInArray(components, oldItemIndex, newItemIndex);
    }

    /**
     * @name GetAllNotionElements
     * @param languageId
     * @param notionId
     * Retrieve all the notion elements from server based on languageId and notionId
     */
    GetAllNotionElements(languageId: number, notionId: number) {
        var data = this.service.GetAllNotionElements(languageId, notionId);
        return data;
    }

    /**
     * @name CreateNewExerciseNotionElement
     * @param body
     * @param notionId
     * @param languageId
     * Create new notion element
     */
    CreateNewExerciseNotionElement(
        body: string,
        notionId: number,
        languageId: number
    ) {
        var data = this.service.CreateNewExerciseNotionElement(
            body,
            notionId,
            languageId
        );
        return data;
    }

    /**
     * @name DeleteExerciseContainer
     * @param exerciseId
     * Delete exercise
     */
    DeleteExerciseContainer(exerciseId: number) {
        var data = this.service.DeleteExerciseContainer(exerciseId);
        return data;
    }

    /**
     * @name DeleteContentItemInsideContentContainer
     * @param contentId
     * Delete content item inside content container
     */
    DeleteContentItemInsideContentContainer(contentId: number) {
        var data = this.service.DeleteContentItemInsideContentContainer(contentId);
        return data;
    }

    /**
     * @name DeleteQuestionItemInsideExerciseContainerById
     * @param questionId
     * Delete question item inside exercise container
     */
    DeleteQuestionItemInsideExerciseContainerById(questionId: number) {
        var data = this.service.DeleteQuestionItemInsideExerciseContainerById(
            questionId
        );
        return data;
    }

    /**
     * @name RemoveExerciseContainer
     * Remove exercise container
     */
    RemoveExerciseContainer(
        list: NotionWithElementsResponseModel,
        item: NotionElementResponseModel,
        components: any[],
        container: ViewContainerRef
    ) {
        var itemIndex = list.notionElementInfo.indexOf(item);
        list.notionElementInfo.splice(itemIndex, 1);
        const component = components[itemIndex];

        container.remove(container.indexOf(component));
        components.splice(itemIndex, 1);
    }

    /**
     * @name GetSectionBySectionId
     * @param sectionId
     * Get section info by section id
     */
    GetSectionBySectionId(sectionId: number) {
        var data = this.notionService.GetSectionBySectionId(sectionId);
        return data;
    }

    /**
     * @name GetAllCourseLearningOutcomesByNotionId
     * @param notionId
     * Get all course learning outcomes based on notion id
     */
    GetAllCourseLearningOutcomesByNotionId(notionId: number) {
        var data = this.learningOutcomeService.GetAllCourseLearningOutcomesByNotionId(
            notionId
        );
        return data;
    }

    /**
     * @name GetAllCourseLearningOutcomesByCourseId
     * @param courseId
     * Get all course learning outcomes by course id
     */
    GetAllCourseLearningOutcomesByCourseId(
        institutionId: number,
        courseId: number
    ) {
        var data = this.learningOutcomeService.GetAllCourseLearningOutcomesByCourseId(
            institutionId,
            courseId
        );
        return data;
    }

    /**
     * @name UpdateNotion
     * @param body
     * @param notionId
     * Update Notion
     */
    UpdateNotion(body: string, notionId: number) {
        var data = this.notionService.UpdateNotion(body, notionId);
        return data;
    }

    /**
     * @name UpdateNotionCourseLearningOutcome
     * @param body
     * @param notionId
     * Update notion course learning outcome list
     */
    UpdateNotionCourseLearningOutcome(body: string, notionId: number) {
        var data = this.learningOutcomeService.UpdateNotionCourseLearningOutcome(
            body,
            notionId
        );
        return data;
    }

    /**
     * @name isNotionTitleTextValid
     * @param title
     * Validate if notion title is valid
     */
    isNotionTitleTextValid(
        title: string,
        errorDefaultTitleMessage: string
    ): boolean {
        if (title) {
            if (title == errorDefaultTitleMessage) {
                return false;
            } else {
                if (title.length < 3) {
                    return false;
                } else {
                    if (title.length > 60) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        } else {
            return false;
        }
    }

    /**
     * @name SwitchNotionEditableIcon
     * @param isEditable
     * Switch between Edit/Safe button
     */
    SwitchNotionEditableIcon(isEditable: boolean) {
        document.getElementById('notionTitle').contentEditable = '' + isEditable;
        document.getElementById('titleEditIcon').classList.toggle('la-pencil');
        document.getElementById('titleEditIcon').classList.toggle('la-check');
    }

    /**
     * @name ShowWarning
     * @param message
     * @param buttonText
     * Show error
     */
    ShowWarning(message: string, buttonText: string) {
        if (buttonText) {
            this._snackBar.ShowWarningWithAction(message, buttonText);
        } else {
            this._snackBar.ShowWarning(message);
        }
    }

    /**
     * @name ShowLoadingDialog
     * @param dialog
     * @param isLoading
     * Show a loading dialog with the progress info
     */
    ShowLoadingDialog(
        dialog: MatDialog,
        isLoading: boolean
    ): MatDialogRef<DialogLoading> {
        var dialogData = new DialogData();
        if (isLoading) {
            dialogData.title = this.translationService.GetTranslatedValue(
                'GENERAL.LOADING'
            );
        } else {
            dialogData.title = this.translationService.GetTranslatedValue(
                'GENERAL.SavingData'
            );
        }
        var progress: number = -1;
        var dialogRef = dialog.open(DialogLoading, {
            data: {
                dialogData: dialogData,
                progress: progress
            }
        });

        return dialogRef;
    }
}
