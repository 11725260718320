import { SerializationHelper } from '../../../../../../src/app/core/helper/serialization-helper';
import { CachingHelper } from '../../../../../../src/app/shared/helper/caching/caching';
import { CachingKeys } from '../../../../../../src/app/shared/helper/caching/caching-keys';
import { GroupResponseModel } from '../../institution/response/group-response.model';

export class LearnerEnrolledCourseResponseModel {
    id: number;
    code: string;
    name: string;
    description: string;
    summary: string;
    status: string;
    classId: number;
    progressPercentage: number;
    isSelfEnrolled: boolean;
    isNotStarted: boolean;
    isAvailable: boolean;
    isInProgress: boolean;
    isCompleted: boolean;
    backgroundImage: string;
    hasExpiryDate: boolean;
    languageId: number;
    languageIsoCode: string;
    language: string;
    isGenerativeAIEnabled: boolean;
    isGeneratedByLearner: boolean;
    hasCertificate: boolean;
    isGeneratedByAI: boolean;
    groups: GroupResponseModel[];

    private static _instance: LearnerEnrolledCourseResponseModel;

    public constructor() {
        //...
    }

    public static get Instance() {
        if (this._instance == null) {
            this._instance = SerializationHelper.toInstance(new LearnerEnrolledCourseResponseModel(), CachingHelper.getItemFromCache(CachingKeys.CourseSession));
        }
        return this._instance;
    }

    /**
     * @name clearFullInstance
     * Remove the instance from object and cache
     */
    public static clearFullInstance() {
        this.clearInstance();
        this.clearInstanceFromCache();
    }

    /**
     * @name clearInstance
     * Remove the instance from object
     */
    public static clearInstance() {
        this._instance = null;
    }

    /**
     * @name clearInstanceFromCache
     * Remove the instance from cache
     */
    public static clearInstanceFromCache() {
        CachingHelper.removeItemFromCache(CachingKeys.CourseSession);
    }

    public static updateInstance(data: LearnerEnrolledCourseResponseModel) {
        CachingHelper.saveItemIntoCache(CachingKeys.CourseSession, JSON.stringify(data));
    }
}
