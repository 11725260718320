import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../http/http-call/global-variables';
import { HttpCall } from '../../http/http-call/http-call';

@Injectable({
    providedIn: 'root'
})
export class UserVerificationApplicationService {
    constructor(
        private globalApiVariables: GlobalApiVariables,
        private http: HttpCall
    ) { }


    /**
     * @name NotifyUserWithAConfirmationCode
     * @param email 
     * @returns data
     * Notify a user with a confirmation code to change his password
     */
    public NotifyUserWithConfirmationCode(email: string) {
        var data = this.http.GetAsync(
            false,
            this.globalApiVariables.ApiSendConfirmationCode,
            email
        );
        return data;
    }


    /**
     * @name SendConfirmationCodeToAuthorizedUser
     * @param email 
     * @returns data
     * Send confirmation code to authorized users
     */
    public SendConfirmationCodeToAuthorizedUser(email: string) {
        var data = this.http.GetAsync(
            true,
            this.globalApiVariables.ApiSendConfirmationCodeToAuthorizedUser,
            email
        );
        return data;
    }


    /**
     * @name ValidateAuthorizedUsersConfirmationCode
     * @param email 
     * @param verificationCode 
     * @returns data 
     * Validate authorized users confirmation code 
     */
    public ValidateAuthorizedUsersConfirmationCode(email: string, verificationCode: string) {
        var data = this.http.PutAsync(
            true,
            null,
            this.globalApiVariables.ApiValidateAuthorizedUsersConfirmationCode,
            email,
            verificationCode
        );

        return data;
    }


    /**
     * @name ValidateConfirmationCode
     * @param email 
     * @param verificationCode 
     * @returns data 
     * Validate confirmation code
     */
    public ValidateConfirmationCode(email: string, verificationCode: string) {
        var data = this.http.PutAsync(
            false,
            null,
            this.globalApiVariables.ApiValidateConfirmationCode,
            email,
            verificationCode
        );

        return data;
    }

    /**
     * @name SetNewPassword
     * @param email 
     * @param newPassword 
     * @returns data 
     * Set new password 
     */
    public SetNewPassword(email: string, newPassword: string) {
        var body = {
            newPassword: newPassword,
        };

        var data = this.http.PatchAsync(
            false,
            JSON.stringify(body),
            this.globalApiVariables.ApiSetNewPassword,
            email
        );

        return data;
    }




}