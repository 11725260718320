import { Injectable } from '@angular/core';
import { UserVerificationApplicationService } from '../../../../../src/app/core/services/user-verification/user-verification-application.service';

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordViewModel {
    constructor(private service: UserVerificationApplicationService) { }

    /**
     * @name NotifyUserWithAConfirmationCode
     * @param email
     * @returns data
     * Notify a user with a confirmation code to change his password
     */
    public NotifyUserWithConfirmationCode(email: string) {
        var data = this.service.NotifyUserWithConfirmationCode(email);
        return data;
    }

    /**
     * @name SendConfirmationCodeToAuthorizedUser
     * @param email
     * @returns data
     * Send confirmation code to authorized users
     */
    public SendConfirmationCodeToAuthorizedUser(email: string) {
        var data = this.service.SendConfirmationCodeToAuthorizedUser(email);
        return data;
    }

    /**
     * @name ValidateConfirmationCode
     * @param email
     * @param verificationCode
     * @returns data
     * Validate confirmation code
     */
    public ValidateConfirmationCode(email: string, verificationCode: string) {
        var data = this.service.ValidateConfirmationCode(email, verificationCode);
        return data;
    }

    /**
     * @name ValidateAuthorizedUsersConfirmationCode
     * @param email
     * @param verificationCode
     * @returns data
     * Validate authorized users confirmation code
     */
    public ValidateAuthorizedUsersConfirmationCode(email: string, verificationCode: string) {
        var data = this.service.ValidateAuthorizedUsersConfirmationCode(email, verificationCode);
        return data;
    }

    /**
     * @name updatePassword
     * @param email
     * @param newPassword
     * @returns data
     * Set new password
     */
    public async updatePassword(email: string, newPassword: string): Promise<boolean> {
        return await this.service
            .SetNewPassword(email, newPassword)
            .toPromise()
            .then((result) => {
                return result.isSuccessful;
            })
            .catch((error) => {
                return null;
            });
    }
}
