import { Injectable } from '@angular/core';
import { GlobalApiVariables } from '../../../http/http-call/global-variables';
import { HttpCall } from '../../../http/http-call/http-call';

@Injectable({
    providedIn: 'root'
})
export class ExerciseElementContainerApplicationService {
    constructor(
        private globalApiVariables: GlobalApiVariables,
        private http: HttpCall
    ) { }

    /**
     * @name UpdateQuestionItemLevel
     * @param body
     * @param exerciseId
     * @param exerciseQuestionId
     * Update question level inside exercise container
     */
    public UpdateQuestionItemLevel(
        body: string,
        exerciseId: number,
        exerciseQuestionId: number
    ) {
        var data = this.http.PatchAsync(
            true,
            body,
            this.globalApiVariables.ApiUpdateExerciseLevel,
            exerciseId,
            exerciseQuestionId
        );
        return data;
    }

    /**
     * @name UpdateQuestionItemOrder
     * @param body
     * Update questions order inside exercise container
     */
    public UpdateQuestionItemOrder(body: string) {
        var data = this.http.PatchAsync(
            true,
            body,
            this.globalApiVariables.ApiUpdateExerciseOrder
        );
        return data;
    }

    /**
     * @name UpdateExerciseTitle
     * @param body
     * Update exercise title
     */
    public UpdateExerciseTitle(
        body: string,
        languageId: number,
        exerciseId: number
    ) {
        var data = this.http.PatchAsync(
            true,
            body,
            this.globalApiVariables.ApiUpdateExerciseTitle,
            languageId,
            exerciseId
        );
        return data;
    }
}
