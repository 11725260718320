import { Injectable } from '@angular/core';
import { UserEmailAvailabilityResponseModel } from '../../../../../src/app/core/models/shared/user/response/user-email-availability.response.model';
import { SignUpInstitutionShortCodeValidationResponseModel } from '../../../../../src/app/core/models/sign-up/response/signup-institution-code.response.model';
import { ManageUserApplicationService } from '../../../../../src/app/core/services/manage-user/manage-user-application-service.service';
import { AccountApplicationService } from '../../../../../src/app/core/services/teacher/account/account.application.service';
import { InstitutionSettingResponseModel } from '../../../core/models/institution/response/institution-settings.response.model';
import { AuthenticationService } from './../../../auth/_services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class SignUpViewModel {
    constructor(private service: AuthenticationService, public accountApplicationService: AccountApplicationService, private manageUserApplicationService: ManageUserApplicationService) { }

    /**
     * @name signUpValidateEmail
     * @param body
     * @returns data
     * sign up validation email
     */
    public async signUpValidateEmail(body: any): Promise<any> {
        return await this.service
            .signUpValidateEmail(body)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * @name verifyEmailCode
     * @param body
     * @returns data
     * verify email code
     */
    public async verifyEmailCode(body: any): Promise<any> {
        return await this.service
            .verifyEmailCode(body)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name getAccountSettingsBasedOnAccountId
     * @param institutionId
     * @returns data
     */
    public async getAccountSettingsBasedOnAccountId(institutionId: number): Promise<InstitutionSettingResponseModel> {
        return await this.accountApplicationService
            .getAccountSettingsBasedOnAccountId(institutionId)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name getAccountSettings
     * @returns
     * Get account settings
     */
    public async getAccountSettings(): Promise<InstitutionSettingResponseModel> {
        return await this.accountApplicationService
            .getAccountSettings()
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name getInstitutionValidationShortCode
     * @param code
     * Checks whether we need to allow the user to input account shortcode or not on sign up
     */
    public async getInstitutionValidationShortCode(): Promise<SignUpInstitutionShortCodeValidationResponseModel> {
        return await this.service
            .getInstitutionValidationShortCode()
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name resendEmailVerificationCode
     * @param body
     * @returns data
     * resend email verification code
     */
    public async resendEmailVerificationCode(body: any) {
        return await this.service
            .resendEmailVerificationCode(body)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return null;
            });
    }

    /**
     * @name signUpLearner
     * @param institutionId
     * @param body
     * @returns data
     * sign up learner
     */
    public async signUpLearner(institutionId: number, body: any): Promise<boolean> {
        return await this.service
            .signUpLearner(institutionId, body)
            .toPromise()
            .then((result) => {
                return result.isSuccessful;
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * @name registerAdminUser
     * @param body
     * @returns data
     * Register admin user
     */
    public async registerAdminUser(body: any): Promise<boolean> {
        return await this.service
            .registerAdminUser(body)
            .toPromise()
            .then((result) => {
                return result.isSuccessful;
            })
            .catch((error) => {
                throw error;
            });
    }

    /**
     * @name checkUserEmailAvailability
     * @param email
     * Check email valdiation
     */
    public async checkUserEmailAvailabilityWithoutAuthentication(email: string): Promise<UserEmailAvailabilityResponseModel> {
        return await this.manageUserApplicationService
            .checkUserEmailAvailabilityWithoutAuthentication(email)
            .toPromise()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                throw error;
            });
    }
}
