export class LiveSessionResponseModel {
    id: number;
    meetingId: string;
    title: string;
    description: string;
    fullDate: Date;
    startFullDate: Date;
    endFullDate: Date;
    date: string;
    startTime: string;
    endTime: string;
    duration: number;
    isInProgress: boolean;
    canStartSession: boolean; //allows the host to start a session
    isToday: boolean;
    status: string;
    isPasswordProtected: boolean;
    instructor: string;
    courseId: number;
    courseTitle: string;
    gradeId: number;
    gradeTitle: string;
    groupId: number;
    groupTitle: string;
    isRecurrentSession: boolean;
    mainRecurrentSessionId?: number;

    formatedStartDate: string;
    formatedEndDate: string;
    enableHostToManageSession: boolean;
}
