import { NotionElementResponseModel } from '../content/notion-element-info-model';
import { ContentModelResponseModel } from '../content/response/content-response-model';
import { NotionResponseModel } from '../notion/response/notion-response-model';

/**
 * Pass data when navigating from content container to content details
 */
export class QuestionNavigationSM {
    exerciseInfo: NotionElementResponseModel;
    itemInfo: ContentModelResponseModel;
    notion: NotionResponseModel;

    isNewQuestion: boolean;

    isBeginner: boolean;
    isIntermediate: boolean;
    isAdvanced: boolean;

    isClassification: boolean;
    isMultipleChoice: boolean;
    isMultipleAnswer: boolean;
    isOpenEnd: boolean;
    isFillInTheBlank: boolean;
    isTrueFalse: boolean;
}
